.App {
  text-align: center;
}

.App-header {
  background-color: #3a0ae5;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-footer {
  background-color: #3a0ae5;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(5px + 1vmin);
  color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}